import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountData, HoldingData } from 'src/app/models/account-grouping.model';
import {
  AccountFormObject,
  CreateFormObject,
  HoldingFormObject,
} from 'src/app/models/create-group.model';
import { environment } from 'src/environments/environment';
import { AccountGroupingDataService } from '../account-grouping-data/account-grouping-data.service';

@Injectable({
  providedIn: 'root',
})
export class PwViewsFormsService {
  createObject: BehaviorSubject<CreateFormObject> = new BehaviorSubject<CreateFormObject>(
    {} as CreateFormObject,
  );
  displayObject: BehaviorSubject<CreateFormObject> = new BehaviorSubject<CreateFormObject>(
    {} as CreateFormObject,
  );
  createEndpointURL: any;
  allInvestments: any;
  private _showCreationSuccessBanner: boolean = false;
  private _showModifySuccessBanner: boolean = false;
  private _showDeleteSuccessBanner: boolean = false;

  constructor(private accountGroupingDataService: AccountGroupingDataService) {
    this.initialCalls();
  }

  private initialCalls(): void {
    this.createEndpointURL = environment.createEndpointURL;
    this.accountGroupingDataService.getAllInvestments().subscribe((invList) => {
      if (invList) {
        this.allInvestments = invList;
        const createObject: any = this.setupCreateObject(invList);
        this.displayObject.next(createObject);
        this.updateCreateObject(createObject);
      }
    });
  }
  //SETUP create form object.
  setupCreateObject(invList: AccountData[]): any {
    let accounts: AccountFormObject[] = [];
    if (invList.length > 0) {
      accounts = invList.map((inv: AccountData) => {
        let holdingsFormList: HoldingFormObject[] = inv.accountPositionIds.map(
          (holding: HoldingData) => {
            let holdingBalance: string = holding?.balance ? holding.balance : '';
            return {
              holdingName: holding?.positionName || '',
              holdingId: holding?.positionId || '',
              holdingSymbol: holding?.ticker || '',
              holdingBalance: holdingBalance,
              isHoldingSelected: false,
            };
          },
        );

        return {
          accountId: inv.accountId,
          accountName: inv.accountName,
          allHoldingsIncluded: false,
          someHoldingsIncluded: false,
          holdings: holdingsFormList,
          isParticipantAccount: inv.accountType === 'PARTICIPANT',
        };
      });
    }

    let createObject: CreateFormObject = {
      viewName: '',
      accounts: accounts,
      areParticipantAccountsSelected: false,
    };

    return createObject;
  }

  updateCreateObject(createObj: CreateFormObject): void {
    this.createObject.next(createObj);
  }

  getCreateObject(): Observable<CreateFormObject> {
    return this.createObject.asObservable();
  }

  getDisplayObject(): Observable<CreateFormObject> {
    return this.displayObject.asObservable();
  }

  set showCreationSuccessBanner(status) {
    this._showCreationSuccessBanner = status;
  }

  get showCreationSuccessBanner(): boolean {
    return this._showCreationSuccessBanner;
  }

  set showModifySuccessBanner(status: boolean) {
    this._showModifySuccessBanner = status;
  }

  get showModifySuccessBanner(): boolean {
    return this._showModifySuccessBanner;
  }

  set showDeleteSuccessBanner(status: boolean) {
    this._showDeleteSuccessBanner = status;
  }

  get showDeleteSuccessBanner(): boolean {
    return this._showDeleteSuccessBanner;
  }
}
